import React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'

import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import FormFieldItemToolId from './FormFieldItemToolId'

import { MACHINES } from '../../utils/constant.utils'
import {
  IProductionOrderApiNew,
  ProductionOrderFormFieldsNew
} from '../../types/productionOrder'
import { OptionItem } from '../../types/common'
import { IPart } from '../../types/parts'
import { ToolsApiData } from '../../types/tools'

import partToolInfoService from '../../service/partToolsInfo.service'
import partService from '../../service/part.service'
import toolService from '../../service/toolLife.service'

interface Props {
  isModalOpen: boolean
  primaryActionHandler: (partData: ProductionOrderFormFieldsNew) => void
  closeHandler: () => void
  defaultData?: IProductionOrderApiNew | null
  isLoading?: boolean
}

const NewProductionOrderModal = (props: Props) => {
  let defaultProductionOderNum: string | number = ''
  let defaultProductionOderQuantity: string | number = ''

  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }

  let defaultSelectedPart: OptionItem = {
    value: 0,
    label: 'Select part number'
  }

  if (props.defaultData) {
    defaultProductionOderNum = props.defaultData.workOrderNum
    defaultProductionOderQuantity = props.defaultData.quantity

    defaultSelectedPart = {
      label: props.defaultData.part.type,
      value: props.defaultData.part.id
    }

    defaultMachine.value = props.defaultData.part.machine
    defaultMachine.label = props.defaultData.part.machine
  }

  const [partNameOptions, setPartNameOptions] = React.useState<IPart[]>([])
  const [productionOrderNum, setProductionOrderNum] = React.useState(
    defaultProductionOderNum
  )
  const [productionOrderQuantity, setProductionOrderQuantity] = React.useState(
    defaultProductionOderQuantity
  )
  const [selectedPartData, setSelectedPartData] =
    React.useState(defaultSelectedPart)
  const [machine, setMachine] = React.useState(defaultMachine)
  const [toolsInfoMap, setToolsInfoMap] = React.useState<
    Record<
      number,
      {
        toolNumber: number
        toolTypeId: number
        toolType: string
        toolId: string | null
      }
    >
  >({})
  const [tools, setTools] = React.useState<ToolsApiData[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (!machine.value) return

    const fetchParts = async () => {
      const response = await partService.getParts({
        machine: machine.value
      })
      if (response.success) {
        setPartNameOptions(response.data)
      }
    }
    fetchParts()
  }, [machine])

  React.useEffect(() => {
    const fetchTools = async () => {
      setIsLoading(true)
      const toolsResponse = await toolService.getTools({})

      if (toolsResponse.success) {
        setTools(toolsResponse.data)
      } else {
        setTools([])
      }

      setIsLoading(false)
    }
    fetchTools()
  }, [machine])

  React.useEffect(() => {
    if (!selectedPartData.value) return

    const fetchParts = async () => {
      const response = await partToolInfoService.getPartToolsInfoByPartId(
        selectedPartData.value
      )
      if (response.success) {
        setToolsInfoMap(
          response.data.reduce((acc: any, item: any, index: number) => {
            return {
              ...acc,
              [index + 1]: {
                toolNumber: item.toolNumber,
                toolTypeId: item.toolTypeId,
                toolType: item.toolType.type
              }
            }
          }, {})
        )
      }
    }
    fetchParts()
  }, [selectedPartData])

  return (
    <StyledModal
      width={500}
      loading={isLoading || props.isLoading}
      isOpen={props.isModalOpen}
      title={
        props.defaultData ? 'Edit production order' : 'Add production order'
      }
      closeHandler={props.closeHandler}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="PO number"
          placeholderText="Enter PO number"
          textValue={productionOrderNum}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setProductionOrderNum(e.target.value)
          }}
          isDisabled={false}
          type="string"
        />
        <StyledTextFieldWithLabel
          label="PO quantity"
          placeholderText="Enter PO quantity"
          textValue={productionOrderQuantity}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value
            if (parseInt(inputValue) < 0) {
              // If it's below zero, set the value to an empty string
              setProductionOrderQuantity('')
            } else {
              // Otherwise, update the state with the new value
              setProductionOrderQuantity(parseInt(inputValue))
            }
          }}
          isDisabled={false}
          type="number"
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledSelectField
          label="Part number"
          placeHolderText="Select part number"
          selectedValue={selectedPartData}
          selectOptions={partNameOptions.map((item) => ({
            value: item.id,
            label: item.type
          }))}
          onChangeHandler={(value: OptionItem) => {
            if (value) {
              setSelectedPartData(value)
            }
          }}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part number found"
        />
      </Stack>
      <Stack sx={{ gap: '8px' }}>
        <Typography sx={{ fontWeight: 600 }}>Tools info</Typography>
        {Object.values(toolsInfoMap).map((item, index) => {
          return (
            <FormFieldItemToolId
              key={index}
              toolId={item.toolId}
              toolIdOptions={tools}
              toolType={item.toolType}
              toolTypeId={item.toolTypeId}
              toolNumber={item.toolNumber}
              toolIdChangeHandler={(value) => {
                setToolsInfoMap((prev) => ({
                  ...prev,
                  [index + 1]: {
                    ...prev[index + 1],
                    toolId: value
                  }
                }))
              }}
            />
          )
        })}
        {Object.values(toolsInfoMap).length === 0 && (
          <Typography>No tools info yet</Typography>
        )}
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.primaryActionHandler({
              toolsInfoMap: Object.values(toolsInfoMap),
              workOrderNum: parseInt(
                String(productionOrderNum).replace(/_/g, '')
              ),
              quantity: productionOrderQuantity as number,
              partId: selectedPartData.value
            })
          }
        >
          {props.defaultData ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default NewProductionOrderModal
