import React from 'react'
import { ExpandMore, Label } from '@mui/icons-material'
import { Stack, Box, Typography, IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { OptionItem } from '../../types/common'

import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { ToolsApiData } from '../../types/tools'

const FormFieldItemToolId = ({
  toolId,
  toolTypeId,
  toolNumber,
  toolType,
  toolIdOptions,
  toolIdChangeHandler
}: {
  toolId: string | null
  toolTypeId: number
  toolIdOptions: ToolsApiData[]
  toolNumber: number
  toolType: string
  toolIdChangeHandler: (value: string) => void
}) => {
  const [showDetails, setShowDetails] = React.useState(true)
  const toggleShowDetails = () => {
    setShowDetails((prev) => !prev)
  }
  const requiredTool = toolIdOptions.find((item) => item.toolId === toolId)
  return (
    <Stack
      sx={{
        border: `1px solid gray`,
        borderRadius: '4px',
        padding: showDetails ? '8px 16px' : '0px'
      }}
    >
      <Box
        onClick={toggleShowDetails}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: showDetails ? '0px' : '6px 12px',
          cursor: 'pointer'
        }}
      >
        <Typography sx={{ color: '#000' }}>Tool {toolNumber}</Typography>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              toggleShowDetails()
            }}
          >
            <ExpandMore
              fontSize="small"
              sx={{
                transform: showDetails ? 'rotate(180deg)' : 'none'
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: showDetails ? 'auto' : 0,
          ...(!showDetails && {
            overflow: 'hidden'
          })
        }}
      >
        <Stack sx={{ flex: 1, gap: '8px' }}>
          <StyledTextFieldWithLabel
            placeholderText="Enter tool number"
            textValue={`${toolNumber}-${toolType}`}
            isDisabled={true}
            type="text"
          />
          <StyledSelectField
            label="Tool ID"
            placeHolderText="Select tool id"
            selectedValue={
              requiredTool
                ? {
                    label: requiredTool.toolId,
                    value: requiredTool.toolId
                  }
                : {
                    label: 'Select tool id',
                    value: 0
                  }
            }
            selectOptions={toolIdOptions
              .filter((item) => item.toolType.id === toolTypeId)
              .map((item) => ({
                label: item.toolId,
                value: item.toolId
              }))}
            onChangeHandler={(selectedOption: OptionItem) =>
              toolIdChangeHandler(selectedOption.value)
            }
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No tool id found"
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default FormFieldItemToolId
