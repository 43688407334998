import React from 'react'
import StyledModal from '../../components/StyledModal/Modal'
import { IProductionOrderApiNew } from '../../types/productionOrder'
import { Box, Typography } from '@mui/material'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  defaultData: IProductionOrderApiNew
}

const ProductionOrderPreviewModal = (props: Props) => {
  return (
    <StyledModal
      width={500}
      isOpen={props.isModalOpen}
      title="Tools info"
      closeHandler={() => props.closeHandler()}
    >
      <Typography> Part number: {props.defaultData.part.type}</Typography>
      {props.defaultData.toolMaps.map((tool) => (
        <Box
          sx={{
            border: '1px solid gray',
            padding: '8px 16px',
            borderRadius: '4px'
          }}
        >
          <Typography>
            Tool {tool.toolNumber}: ({tool.toolType.type} - {tool.tool.toolSize}
            )
          </Typography>
          <Typography> Tool ID: {tool.toolId}</Typography>
          <Typography> Tool life: {tool.tool.toolLife}</Typography>
        </Box>
      ))}
    </StyledModal>
  )
}

export default ProductionOrderPreviewModal
